import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import ky from "ky";

import Header from "../Header/Header";
import ParticipantTable from "../Table/ParticipantTable";
import Footer from "../Footer/Footer";
import Stat from "../Stats/Stat";
import NetIncomeChart from "../Charts/NetIncomeChart";

const Participants = ({ datastore }) => {
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [participantJson, setParticipantJson] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    if (
      isLogged === false &&
      (window.sessionStorage.getItem("isAdminLoggedIn") !== "true" ||
        window.sessionStorage.getItem("isAdminLoggedIn") === null)
    ) {
      return;
    }
    setIsLoading(true);
    ky.get(window._env_.REACT_APP_API_URL + "/participants")
      .json()
      .then((json) => {
        setIsLoading(false);
        setParticipantJson(json);
        setIsError(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        console.log(err);
      });
  }, [isLogged]);

  const debounceDelay = 500; // Delay in milliseconds

  useEffect(() => {
    if (password === null || password === "") {
      return;
    }

    const handler = setTimeout(() => {
      setIsLoading(true);

      ky.post(window._env_.REACT_APP_API_URL + "/admin/login", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        json: { configId: datastore.config.configId, password },
      })
        .text()
        .then((resp) => {
          if (resp === "true") {
            window.sessionStorage.setItem("isAdminLoggedIn", true);
            setIsLogged(true);
          } else {
            // Handle the case where the response is not "true"
            setIsLoading(false);
            setIsError(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
          console.log(err);
        });
    }, debounceDelay);

    return () => clearTimeout(handler);
  }, [password]);

  if (
    isLogged === false &&
    (window.sessionStorage.getItem("isAdminLoggedIn") !== "true" ||
      window.sessionStorage.getItem("isAdminLoggedIn") === null)
  ) {
    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box px={3} py={2} flexGrow={1} textAlign="center">
          <Box py={3}>
            <Typography variant="h4">Admin</Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h5">
              Please enter the admin password below:
            </Typography>
          </Box>
          <Box py={2}>
            <TextField
              variant="outlined"
              label="Password"
              type="text"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Footer />
      </Box>
    );
  } else {
    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box flexGrow={1}>
          <Typography variant="h4" pt={3} sx={{ textAlign: "center" }}>
            <strong>Participants</strong>
          </Typography>
          <Box pl={3} pr={3}>
            {isLoading ? (
              <Typography variant="h6" pt={3} sx={{ textAlign: "center" }}>
                <strong>Loading...</strong>
              </Typography>
            ) : null}
            {!isLoading && isError ? (
              <Typography variant="h6" pt={3} sx={{ textAlign: "center" }}>
                <strong>Error loading data...</strong>
              </Typography>
            ) : null}
            {!isLoading && participantJson ? (
              <>
                <Stat datastore={datastore} participantJson={participantJson} />
                <NetIncomeChart data={participantJson} />
                <ParticipantTable
                  datastore={datastore}
                  participantJson={participantJson}
                />
              </>
            ) : null}
          </Box>
        </Box>
        <Footer />
      </Box>
    );
  }
};
export default Participants;

import React from "react";
import {
  Typography,
  Box,
  Grid,
  Container,
  Paper,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Home = ({ datastore }) => {
  const sections = [
    {
      title: "Who",
      text: datastore.config.keyInfo.who,
    },
    {
      title: "What",
      text: datastore.config.keyInfo.what,
    },
    {
      title: "Where",
      text: [
        <Typography
          onClick={() =>
            window.open("https://goo.gl/maps/uCkND6NQQf9UNTH46", "_blank")
          }
          key="where-link"
          sx={{ cursor: "pointer", textDecoration: "underline" }} // Style as a link
        >
          {datastore.config.eventAddress.name}
        </Typography>,
        <iframe
          title="map"
          src={datastore.config.eventAddress.googleMapsEmbedUrl}
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          style={{ border: 0, marginTop: "16px" }}
          key="where-map"
        />,
      ],
    },
    { title: "When", text: datastore.config.keyInfo.when },
    {
      title: "Why",
      text: datastore.config.keyInfo.why,
    },
    {
      title: "How",
      text: datastore.config.keyInfo.how,
    },
  ];
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  return (
    <Box sx={{ textAlign: "center" }}>
      <Header />
      <Box
        sx={{
          pt: 3,
          display: "flex",
          flexDirection: "column", // Stack children vertically
          alignItems: "center", // Center children horizontally
          justifyContent: "center", // Center children vertically
        }}
      >
        <Box
          component="img"
          src="https://raw.githubusercontent.com/jbyerline/Crew-324-Logos/0832ec1d35d50659ecb9e08de3c2062e3d863821/crew324_back_logo.svg"
          alt="logo"
          sx={{
            width: { xs: "250px", md: "350px", lg: "450px" }, // Adjust sizes as per your needs
          }}
        />
        <Typography
          variant="h4"
          sx={{
            color: "#fff", // White color
            "-webkit-text-stroke": {
              xs: "1px #046a38",
              md: "1.5px #046a38",
              lg: "2px #046a38",
            }, // Green outline
            fontWeight: "bold",
            fontSize: { xs: "2rem", md: "3rem", lg: "3.5rem" }, // Adjust sizes as per your needs
            letterSpacing: "0.1em", // Adjust the spacing as per your requirement
            transform: "scaleX(1.2)", // Stretch the text horizontally
            mb: 1,
          }}
        >
          BROOMBALL
        </Typography>
      </Box>
      <Button
        onClick={() => {
          console.log("Clicked");
          navigate("/register/info");
        }}
        variant="outlined"
        sx={{ mb: 3 }}
      >
        Click Here to Register!
      </Button>
      <Container>
        <Grid container spacing={isMdUp ? 3 : 1}>
          <Grid item xs={12} md={6}>
            {sections.slice(0, 3).map(({ title, text }) => (
              <Paper
                key={title}
                sx={{
                  p: 3,
                  mb: 3, // Adjust bottom margin based on screen width
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.background.default,
                  borderRadius: 2,
                  position: "relative",
                  overflow: "hidden",
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    opacity: 0.1,
                    zIndex: -1,
                  },
                }}
              >
                <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
                  {title}
                </Typography>
                {text.map((paragraph, index) => (
                  <Box key={`${title}-text-${index}`} sx={{ mb: 1 }}>
                    {paragraph}
                  </Box>
                ))}
              </Paper>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            {sections.slice(3).map(({ title, text }) => (
              <Paper
                key={title}
                sx={{
                  p: 3,
                  mb: 3,
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.background.default,
                  borderRadius: 2,
                  position: "relative",
                  overflow: "hidden",
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    opacity: 0.1,
                    zIndex: -1,
                  },
                }}
              >
                <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
                  {title}
                </Typography>
                {text.map((paragraph, index) => (
                  <Box key={`${title}-text-${index}`} sx={{ mb: 1 }}>
                    {paragraph}
                  </Box>
                ))}
              </Paper>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Home;
